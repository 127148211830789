import moment from "moment-timezone";
import { useAnalyticsContext } from '../../config/GoogleTagManagerEvents';
import { useCartState } from '../../contexts/cart';
import { useFilterState } from '../../components/cap';
import useAnalytics from './useAnalytics';
import { useCheckDeliveryCharge } from '../useCheckDeliveryCharge';
import { useCheckUser } from '../useCheckUser';
import useCheckout from '../useCheckout';
import { useSunbeltLocation } from '../useSunbeltLocation';
import useReservation from '../../components/checkoutv2/hooks/useReservation';
import { getRentalDuration } from '../../components/global/utils/commonUtils';
import { cardTypeMapper } from '../../components/checkoutv2/checkoutPayment/paymentHelper';
import { logError } from '../../components/global/utils/logger';
import { getProducts } from '../../constants/analyticsConstants/getProducts';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../constants/analyticsConstants/Variables';
import { EVENT_PAGE_NAMES_CONFIG } from '../../constants/analyticsConstants/Page';
import { DELIVERY_CHARGE_FLAG } from '../../constants/cartConstants';
import { USER_TYPE } from '../../constants/userDetailsConstants';

const useCheckoutAnalytics = () => {
    const [
        {
            cart,
            howToGetYourOrderDetails,
            projectInfo,
            consumables,
            timeZoneID,
            orderSummaryDetails,
            paymentData,
            paymentTokenData
        }
    ] = useCartState();
    const [{ projectDetails, startDate, endDate, viewCart }] = useFilterState();
    const { checkoutStepPayload } = useCheckout();
    const {
        sendEventsForEcommerceAction,
        sendEventsForEcommercePage,
        sendEventsForPageUser,
        sendEventsForUpdateVirtualPath,
        sendEventsForEcommerceCheckoutOption,
        sendEventsForEcommerceCheckout,
        sendEventsForEcommercePurchase
    } = useAnalyticsContext();
    const [{ payloadEcommerceActionAnalytics, payloadEcommerceLocationActionAnalytics }] = useAnalytics();
    const sunbeltLocation = useSunbeltLocation();
    const deliveryChargeFlag = useCheckDeliveryCharge();
    const [{ getConfirmationMessage }] = useReservation();
    const userType = useCheckUser();
    const orderEstimates = cart?.estimatesResponse?.estimate;
    const { rentalAmount, deliveryPickUpCharges, fuelCharges, rppCharges, salesTax, allOtherCharges } =
        orderEstimates?.totals || {};
    const envFeeObj = orderEstimates?.miscCharges?.find(item => item.type.indexOf('ENVIRONMENTAL') > -1);
    const { charge = 0 } = envFeeObj || {};
    const selectedStoreDetails = JSON.parse(
        localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTEDSTOREDETAILS) || '{}'
    );

    const triggerCheckoutEvents = () => {
        try {
            sendEventsForEcommercePage(
                VARIABLE_CONFIG.ECOMMERCE_PAGE.CHECKOUT,
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) == 2
                    ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                    : VARIABLE_CONFIG.CURRENCY_CODE.USA
            );
            sendEventsForPageUser(EVENT_PAGE_NAMES_CONFIG.PAGE_USER, payloadEcommerceLocationActionAnalytics());
        } catch (error) {
            logError(error, false, 'triggerCheckoutEvents');
        }
    };

    const triggerCheckoutStepEvents = (isStepForward, formStep, currentStep) => {
        try {
            if (isStepForward) {
                sendEventsForEcommerceAction(
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CHECKOUT_STEP_COMPLETED,
                    payloadEcommerceActionAnalytics(),
                    checkoutStepPayload(formStep, currentStep, 1)
                );
            }
            sendEventsForEcommerceAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CHECKOUT_STEP_VIEWED,
                payloadEcommerceActionAnalytics(),
                checkoutStepPayload(formStep, currentStep, 0)
            );
        } catch (error) {
            logError(error, false, 'triggerCheckoutStepEvents');
        }
    };

    const getDate = selectedDate => {
        const stringToDate = new Date(selectedDate);
        return moment(stringToDate).format('l');
    };

    const sendEventsForPickup = (stepNumber, startDate, endDate) => {
        try {
            const currencyCode =
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) == 2
                    ? VARIABLE_CONFIG.REGION.CANADA
                    : VARIABLE_CONFIG.REGION.US;
            sendEventsForEcommerceCheckoutOption(stepNumber, {
                pickupDate: getDate(startDate),
                pickupTime: howToGetYourOrderDetails?.selectedPickupTime,
                returnDate: getDate(endDate),
                returnTime: howToGetYourOrderDetails?.selectedReturnTime,
                rentalDuration: getRentalDuration(startDate, endDate),
                timeZone: timeZoneID,
                rentalSubtotal: rentalAmount,
                estimatedSubtotal: rentalAmount + salesTax,
                pickupInstructions:
                    projectInfo?.accessNotes ||
                    projectDetails?.accessNotes ||
                    VARIABLE_CONFIG.EMPTY_STRING.PAGE_UNSPECIFIED,
                sunbeltLocation: sunbeltLocation
            });
            sendEventsForUpdateVirtualPath(
                VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CHECKOUT_PICKUP_LOCATION,
                `/${currencyCode}/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.PICKUP_LOCATION}`
            );
        } catch (error) {
            logError(error, false, 'sendEventsForPickup');
        }
    };

    const getDeliveryFee = payload => {
        if (deliveryChargeFlag === DELIVERY_CHARGE_FLAG.TBD) {
            return DELIVERY_CHARGE_FLAG.TBD;
        }
        return payload?.deliveryCharge;
    };

    const getFulfillmentDetails = () => {
        const { pc, fulfillmentPercent } = selectedStoreDetails || {};
        return {
            fulfillment_pc: pc || viewCart?.pc,
            fulfillment_ratio: fulfillmentPercent
        };
    };

    const triggerOnSuccessEvents = (datesObj, payload, data, currentStep) => {
        try {
            sendEventsForEcommerceCheckout(7, getProducts(cart, datesObj, false, consumables));
            sendEventsForEcommercePurchase(
                payload?.companyId === '2' ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA : VARIABLE_CONFIG.CURRENCY_CODE.USA,
                data?.createReservation?.data?.oms_order_id,
                parseFloat(orderSummaryDetails?.estimatedSubtotal),
                salesTax,
                rentalAmount + charge,
                parseFloat(allOtherCharges).toFixed(2),
                payload?.optOutRPP === 'Y' ? rppCharges : 0,
                payload?.optOutFuelCharge === 'Y' ? fuelCharges : 0,
                charge,
                getDeliveryFee(payload),
                deliveryPickUpCharges,
                payload?.zip,
                payload?.zip,
                userType !== USER_TYPE.CREDIT
                    ? cardTypeMapper(paymentData?.selectedPaymentDetails?.paymentType)?.toLowerCase() ||
                          paymentTokenData?.ccBrand
                    : VARIABLE_CONFIG.ACCOUNT_PAYMENT_TYPE.CREDIT,
                payload?.deliveryCharge === 0
                    ? VARIABLE_CONFIG.RENTAL_METHOD.PICKUP
                    : VARIABLE_CONFIG.RENTAL_METHOD.DELIVERY,
                getConfirmationMessage().message,
                getRentalDuration(startDate, endDate),
                sunbeltLocation,
                getProducts(cart, datesObj, false, consumables),
                getFulfillmentDetails()
            );
            sendEventsForUpdateVirtualPath(
                VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CONFIRMATION,
                `/${
                    localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) == 2
                        ? VARIABLE_CONFIG.REGION.CANADA
                        : VARIABLE_CONFIG.REGION.US
                }/${VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CONFIRMATION}`
            );
            sendEventsForEcommerceAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CHECKOUT_STEP_COMPLETED,
                payloadEcommerceActionAnalytics(),
                checkoutStepPayload({ SUBMIT_RESERVATION: 'SUBMIT_RESERVATION' }, currentStep, 0, true)
            );
        } catch (error) {
            logError(error, false, 'triggerOnSuccessEvents');
        }
    };

    return [
        {
            triggerCheckoutEvents,
            triggerCheckoutStepEvents,
            sendEventsForPickup,
            triggerOnSuccessEvents
        }
    ];
};

export default useCheckoutAnalytics;

import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import OrderSummaryShimmer from '../molecules/orderSummaryFactory/components/orderSummaryShimmer/OrderSummaryShimmer';
import { FACTORYSOURCE } from '../molecules/orderSummaryFactory/constants/orderSummaryFactoryConstants';

const CheckoutFallbackShimmer = () => {
    const intl = useIntl();
    
    return (
        <section className="shimmer-container block-gradient block-checkout checkout-shimmer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-7 block-checkout-left checkout-steps-container">
                        <h4 className="block-checkout-title">
                            {intl.formatMessage({ id: 'checkout:checkout' })}
                        </h4>
                        <p className="requiredFields">
                            {intl.formatMessage({ id: 'checkout:required-fields' })}
                        </p>
                        <div className="left-details-shimmer-container">
                            <div className="left-details-shimmer checkout-shimmer"></div>
                            <div className="left-details-shimmer checkout-shimmer"></div>
                            <div className="left-details-shimmer checkout-shimmer"></div>
                            <div className="left-details-shimmer checkout-shimmer"></div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 block-checkout-right">
                        <OrderSummaryShimmer variant={FACTORYSOURCE.CHECKOUT} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default memo(CheckoutFallbackShimmer);
import { createLazyImports } from '../../global/lazyLoad/lazyLoadUtils';

const componentPaths = {
    LazySubmitReservationButton: () =>
        import(/* webpackChunkName: 'cmp-submitReservationButton' */ '../../global/atoms/SubmitReservation'),
    LazyCheckoutGuestUser: () => import(/* webpackChunkName: 'cmp-checkoutGuestUser' */ '../CheckoutGuestUser'),
    LazyCheckoutCashUser: () => import(/* webpackChunkName: 'cmp-checkoutCashUser' */ '../CheckoutCashUser'),
    LazyCheckoutCreditUser: () => import(/* webpackChunkName: 'cmp-checkoutCreditUser' */ '../CheckoutCreditUser'),
    LazyReservationConfirmation: () => import(/* webpackChunkName: 'cmp-reservationConfirmation' */ '../confirmation'),
    LazyQuoteConfirmation: () =>
        import(
            /* webpackChunkName: 'cmp-quoteConfirmation' */ '../../checkoutv2/transmitOrSave/QuoteConfirmation/QuoteConfirmation'
        ),
    LazyQuoteTransmitModal: () =>
        import(/* webpackChunkName: 'cmp-quoteTransmitModal' */ '../../quotes/qouteTransmitModal/QuoteTransmitModal'),
    LazyTransmitOrSaveMain: () =>
        import(/* webpackChunkName: 'cmp-transmitOrSaveMain' */ '../../checkoutv2/transmitOrSave/TransmitOrSaveMain'),
    LazyCheckoutStepsAndOrderSummary: () =>
        import(/* webpackChunkName: 'cmp-checkoutStepsAndOrderSummary' */ '../CheckoutStepsAndOrderSummary'),
};

const LazyLoadCheckoutImports = createLazyImports(componentPaths);

export const {
    LazySubmitReservationButton,
    LazyCheckoutGuestUser,
    LazyCheckoutCashUser,
    LazyCheckoutCreditUser,
    LazyReservationConfirmation,
    LazyQuoteConfirmation,
    LazyQuoteTransmitModal,
    LazyTransmitOrSaveMain,
    LazyCheckoutStepsAndOrderSummary
} = LazyLoadCheckoutImports;